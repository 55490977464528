html, body {
    font-size:16px;
    font-family: 'Poppins', sans-serif;
    color:#000000;
}
a {
    color:#000;
    cursor: pointer;
} 
*:focus {
    outline: none;
    outline:0px !important;
    box-shadow: none !important;
}
.clearspace {
    margin: 1rem 0;
    display: block;
} 
.heading-2 {
    font-size:1.5rem;
    color:#000000;
    font-weight: 500;
}
.heading-layer {
    margin-bottom: 1.375rem;
}
.heading-3 {
    font-size: 1.1rem;
    color:#000000;
    font-weight: 500;
}
.heading-4 {
    font-size:1rem;
    color:#000000;
    font-weight: bold;
}
.heading-4.white {
    color:#fff;
}
.heading-5 {
    font-size:1rem;
    color:#1C008A;
    font-weight: 400;
}
.heading-6 {
    font-size:1rem
}
.heading-7{
    font-size:2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.upper-title-1 {
    font-size:1.3rem;
    color:#000000;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
}
.upper-title-1 i {
    position:absolute;
    top: 0.3rem;
    left: -2rem;
    z-index: 5;
    cursor: pointer;
}
.btn-style-1 {
    min-width: 20rem;
    max-width: 100%;
    height:3.5rem;
    line-height: 3.5rem;
    text-align: center;
    color:#fff;
    font-size:1rem; 
    font-weight: 700;
    background-image: linear-gradient(to right, #D7008A 0%, #EB525D 51%, #FFA72E 100%);
    border-radius: 20px;
    cursor: pointer;
    border:none;
    text-transform: uppercase;
    display: inline-block;
}
.btn-style-1.small {
    min-width: 12.25rem;
}
.btn-style-2.small {
    min-width: 9rem;
}
.btn-style-1:disabled {
    opacity:0.5;
    cursor: default;
}
.btn-style-1:disabled:hover {
    opacity:0.5;
}
.btn-style-1:hover {
    opacity:0.7;
}
.btn-style-2 {
    min-width: 17.5rem;
    background-color: white;
    max-width: 100%;
    height: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    color: #363636;
    font-size: 1rem;
    font-weight: 550;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #363636;
    text-transform: uppercase;
    display: inline-block;
}
.btn-style-2:disabled {
    opacity:0.5;
    cursor: default;
}
.btn-style-2:disabled:hover {
    opacity:0.5;
}
.btn-style-2:hover {
    opacity:0.7;
}
.btn-style-3 {
    min-width: 12.5rem;
    max-width: 100%;
    height:3.5rem;
    text-align: center;
    color:#fff;
    font-size:1rem;
    font-weight: 700;
    background-color:#1C008A;
    border-radius: 20px;
    cursor: pointer;
    border:none;
    display: inline-block;
    vertical-align: top;
    line-height: 3.4rem;
}
.btn-style-3:disabled {
    opacity:0.5;
    cursor: default;
    color:#fff;
}
.btn-style-3:disabled:hover {
    opacity:0.5;
    color:#fff;
}
.btn-style-3:hover {
    opacity:0.7;
    color:#fff;
    text-decoration: none;
}
.btn-style-4 {
    min-width: 22rem;
    max-width: 100%;
    padding: 0rem 1.5rem;
    height: 3.5rem;
    text-align: center;
    color: #1C008A;
    font-size: 1rem;
    font-weight: 500;
    background: none;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #1C008A;
    display: inline-block;
    vertical-align: top;
    line-height: 3.4rem;
    text-transform: uppercase;
}
.btn-style-4:hover {
    opacity:0.7;
    text-decoration: none;
}
.btn-style-5 {
    min-width: 10.25rem;
    max-width: 100%;
    height:2.875rem !important;
    text-align: center;
    color:#1C008A;
    font-size:1rem;
    font-weight: 500;
    background:none;
    border-radius: 16px;
    cursor: pointer;
    border:1px solid #1C008A;
}
.btn-style-5 span {
    display: flex;
    font-weight: 550;
    align-items: center;
    margin-top: -0.1rem;
}
.btn-style-5 i {
    margin-right: 0.7rem;
    font-size: 1.2rem;
}
.btn-style-5:hover {
    opacity:0.7;
    text-decoration: none;
}
.btn-style-6:hover {
    opacity:0.7;
    text-decoration: none;
  }
  .btn-style-6{
    min-width: 10.25rem;
    width:70%;
    max-width: 100%;
    height:56px;
    line-height: 2.875rem;
    text-align: center;
    color:#e74e5a;
    font-size:1rem;
    font-weight: 550;
    background:none;
    border-radius: 26px;
    cursor: pointer;
    border:1px solid #e74e5a;
    display: flex;
    align-content: center;
  }
  .btn-style-6 span {
    display: flex;
    padding:0 1rem;
    align-self: center;
    margin-top: -0.1rem;
    margin:auto;
  }
.link-style-1 {
    font-size: 0.875rem;
    color:#1C008A;
    font-weight: 400;
}
.text {
    color:#363636;
    font-size:1rem;
}
.heading-layer .text {
    margin-top: 0.625rem;
    font-size: 0.875rem;
}
.text-note {
    font-size: 0.75rem;
    color:#fff
}
.text-note a {
    text-decoration: underline;
    font-weight: 500;
    color:#fff
}
.description {
    color:#000
}
.action-link {
    height: 2.875rem;
    width: 2.875rem;
    line-height: 2.875rem;
    border:1px solid #000;
    border-radius: 25px;
    color:#000;
    font-size: 1.2rem;
    text-align: center;
    display: inline-block;
}
.action-link.success {
    color:#00B6A8;
    border-color:#00B6A8;
}
.action-link.primary {
    color:#1C008A;
    border-color:#1C008A;
}
.action-link.danger {
    color:#E74E5A !important;
    border-color:#E74E5A;
}
.action-link.default {
    color:#CDCACA;
    border-color:#CDCACA;
}
.action-link:hover {
     opacity:0.7
}
.action-link.attach-icon img {
    -webkit-transform:scale(1, -1);
    -moz-transform:scale(1, -1);
    transform:scale(1, -1)
}
i.verified {
    background-image: url(../images/verified.svg);
    background-repeat: no-repeat;
    background-position:center center;
    height: 100%;
}
.mutual-friends{
    font-size: 0.9rem;
    font-weight: 500;
    color: #1C008A;
    margin-top: -1rem;
    position: relative;
}
.label-style-1{
    font-size: 1.1rem;
    font-weight: 550;
    color: #1C008A;
    position: relative;
}
.label-style-1 i{
    position: absolute;
    top: 0.3rem;
    color: black;
    font-size: 1.3rem;
}
.label-style-2 {
    font-size:1rem;
}
.label-style-3 {
    font-style: italic;
    font-size: 0.875rem;
}
.label-style-5 {
    font-size:0.875rem;
    color: #CDCACA;
    margin-bottom: 0.2rem;
}
.label-style-6{
    font-size: 0.8rem;
    font-weight: 550;
    color:#1C008A;
    margin-top:2rem;
    text-transform: uppercase;
}
/****MENUS***************************************************************/
ul.menu-style-1,
ul.menu-style-1 li {
    padding:0;
    margin:0;
    width: 100%;
    list-style:none
}
ul.menu-style-1 li a {
    font-size: 0.975rem;
    color:#000 ;
    padding: 1.1rem 1rem;
    display: inline-block;
    width: 100%;
    background-color: #F5F5F5;
    position: relative;
}
ul.menu-style-1 li a:hover {
    color:#1C008A !important
}
.dashboard-grey ul.menu-style-1 li.active i{
    opacity: 0;
}
ul.menu-style-1 li.active a {
    color:#1C008A !important;
    background-color: #D8D5E5;
    font-weight: 550;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.connections-middle .menu-style-1 li.active a{
    background-color: #F1EFFB !important;
}
.btn-style-9{
    height: 110px;
    cursor: pointer;
    width: 100%;
    background-color: #F5F5F5;
    color: #363636;
    line-height: 3rem;
    font-weight: 500;
    border-radius: 10px;
    margin-bottom: 1rem;
    font-size: 0.837rem;
    border: 1px solid #DBDBDB;
}
.btn-style-9:hover {
    opacity:0.7;
  }
  .btn-style-10{
    min-height:42px;
    width:96px;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 550;
    background-color: #1C008A;
    border:none;
    float: right;
    position: absolute;
    right:    5%;
    cursor: pointer;
    bottom:   5%;
  }
  .btn-style-10:hover {
    opacity:0.7;
  }
ul.menu-style-1 li.active a .fa-chevron-down,ul.menu-style-1 li a .fa-chevron-up {
    display: none;
}
ul.menu-style-1 li.active a .fa-chevron-up{
display: block;
color: #1100A0 !important;
}
ul.menu-style-1 li a i {
    position: absolute;
    top: 1.3rem;
    right: 1.25rem;
    z-index: 1;
    font-size: 1.2rem;
    color: #CDCACA;
}
ul.menu-style-1 li a {
 
    cursor: pointer !important;
}
ul.menu-style-1:not(.start) li,
ul.menu-style-1:not(.start) li a {
    cursor: pointer !important;
}
ul.menu-style-2 {
    padding: 0;
    margin: 0;
    width: 30%;
    background-color: white;
    list-style: none;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
    border-radius: 20px;
}
ul.menu-style-2 a {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 0.9rem 1rem;
    border-radius: 20px;
    text-align: center;
}
ul.menu-style-2 li:last-child {
    margin-right: 0;
}
ul.menu-style-2  li a{
    font-size:1rem;
    font-weight: 400;
    position: relative;
}
app-connections-nav ul.menu-style-2 li,.notifications-dropdown ul.menu-style-2 li,.agreement-terms ul.menu-style-2 li,.documents-row1 ul.menu-style-2 li{
    width: 50% !important;
}
ul.menu-style-2 li{
    width: 35%;
    display: inline-block;
}
ul.menu-style-2  a.active {
    font-weight: 600;
    background-color: #1C008A;
    color: white !important;
}
/****END MENUS***************************************************************/
/****TABS***************************************************************/

ul.tabs-style-2 {
    padding:0;
    list-style: none;
}
ul.tabs-style-2 li {
    display: inline-block;
    vertical-align: top;
    margin:0 0.375rem;
}
ul.tabs-style-2 li a {
    display: inline-block;
    vertical-align: top;
    height: 2.5rem;
    line-height: 2.5rem;
    padding:0 1.5rem;
    border-radius: 50px;
    color:#2C2C2C;
    background-color: #ffffff;
    text-transform: capitalize;
    text-decoration: none;
}
ul.tabs-style-2 li a:hover,
ul.tabs-style-2 li a.active {
    background-color: #FFA72E;
    color:#fff !important;
}
/****END TABS***************************************************************/
.search-input  {
    position: relative;
}
.search-input i {
    position: absolute;
    top: 0.7rem;
    left: 1rem;
    transform: rotate(90deg);
    color: #CDCACA;
    font-size: 1.5rem;
}
.search-input input[type="text"] {
    width: 100%;
    height:3rem;
    border: 1px solid #DBDBDB;
    border-radius: 20px;
    color:#000000;
    padding:0 0 0 3.25rem;
}
/**************************************************************************/
.form-group {
    position: relative;
}
.form-group input[type="checkbox"],
ul li input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    position: relative;
}
.form-group input[type="checkbox"]:checked,
ul li input[type="checkbox"]:checked {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    text-align: center;
    background-color: #000000;
    border-color: #000000;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 4px;
}
.form-group input[type="checkbox"]:checked:before,
ul li input[type="checkbox"]:checked:before {
    width: 1.3rem;
    height: 1.3rem;
    content: "\f00c";
    position: absolute;
    top: .1rem;
    left: .1rem;
    z-index: 1;
    background-color: #fff;
    border-radius: 2px;
}
.form-group .invalid-feedback {
    position: absolute;
    top: 100%;
}
a:hover{
   color: #1C008A;
}
.form-group .form-control,.input-control {
    border: 1px solid #DBDBDB;
    border-radius: 10px !important;
    background-color: white;
    height: 36px;
    padding: .375rem .75rem;
    font-size: 0.9rem;
}
.form-group .form-control.tag-input {
    height: auto;
    min-height: 3rem;
}
.form-group .ng2-tags-container tag,
.form-group .ng2-tags-container tag:hover,
.form-group .ng2-tags-container tag:focus {
    background:none !important;
    color:#363636 !important;
    box-shadow: none !important;
}
.form-group .position-relative .form-control {
    padding-left: 2.6rem;
    border-radius: 25px !important;
}
.input-search-zoom {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    z-index: 2;
    font-size: 1.3rem;
    width: 1.6rem;
    color: #DBDBDB;
    text-align: center;
}
.input-search-zoom.YouTube i:before {
    content:"\f167"
}
.input-search-zoom.LinkedIn i:before {
    content:"\f0e1"
}
.input-search-zoom.Facebook i:before {
    content:"\f39e"
}
.input-search-zoom.Instagram i:before {
    content:"\f16d"
}
.input-search-zoom.Twitter i:before {
    content:"\f099"
}
.input-search-zoom.Snapchat i:before {
    content:"\f2ab"
}
.form-group textarea.form-control {
    height: 4rem;
    resize:none
}
.address-form-container .form-group textarea.form-control {
    height: 4rem;
}
.form-group .form-control.ng-touched.ng-invalid {
    border-color:red
}
.form-group .form-control.ng-touched.ng-invalid input,
.form-group.invalid-mobile-exists input {
    border-color:red !important
}
.form-group label {
    font-size: 1rem;
    color: #363636;
    opacity: 85%;
    text-transform: capitalize;
}
.form-group label b {
    font-weight: unset;
}
.form-group label.no-capitalize {
    text-transform: initial;
}
.form-group label small {
    font-size:0.875rem;
    text-transform:initial;
}
.form-group label a {
    font-weight: bold;
}
.form-group .input-group-prepend {
    cursor: pointer;
    position: absolute;
    top: 2.7rem;
    right: 1rem;
    z-index: 100;
}
.input-group-prepend {
    color:#CDCACA
}
.form-group:focus-within .input-group-prepend {
    color:#000 !important
}
.form-note {
    font-size:0.875rem;
    margin-top: 1rem;
}
.form-group .form-note {
    margin-top: 0.2rem;
}
.form-group .form-note a:hover {
    text-decoration: underline;
}
.form-note a {
    color: #1100A0 !important;
    text-transform: uppercase;
    font-size: 1rem;
}
.form-note-grey {
    color:#787878;
    font-size:0.75rem;
    margin-top: 1rem;
}
.form-group .invalid-feedback {
    display: block;
    font-size: 0.75rem;
}
.form-group .input-tooltip {
    margin-left: 0.3rem;
    color:#CDCACA;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focud+.map-form-field-label-wrapper .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform:translateY(-1.27em) scale (0.75) !important
}
/**ngx intel overriden styles******************************************************************/
.form-group ngx-intl-tel-input .iti__flag-container {
    width: 5.25rem;
}
.form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag {
    border:1px solid #CDCACA;
    border-radius: 8px;
    padding:0;
    width: 100% !important;
    text-align: center;
    background-color: white;
}
.form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag > div  {
    margin:0 auto !important
}
.form-group ngx-intl-tel-input .iti__flag-container .iti__flag {
    display: none;
}
.form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag > div.iti__arrow,
.form-group .mat-form-field .mat-select .mat-select-arrow {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    border: none;
    position: relative;
    top: -0.4rem;
    right: 1.2rem;
    color:#CDCACA
}
.form-group .mat-form-field .mat-select .mat-select-arrow {
    font-size: 1rem;
    color:#CDCACA
}
.form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag > div.iti__arrow:before,
.form-group .mat-form-field .mat-select .mat-select-arrow:before {
    content: "\f078";
}
.form-group ngx-intl-tel-input .iti__flag-container {
    font-size:1rem
}
.form-group ngx-intl-tel-input .iti {
    width: 100%;
    height: 100%;
}
.form-group .country-dropdown .iti__flag-box {
    display: none;
}
.form-group ngx-intl-tel-input input[type="tel"] {
    border:none;
    height: 100%;
    border:1px solid #DBDBDB;
    border-radius: 8px;
    padding:0 1rem !important;
    width: calc(100% - 6.25rem);
    float:right
}
.form-group ngx-intl-tel-input.form-control {
    border:none;
    padding:0;
    background-color: unset;
}
/********************************************************************/
.form-group .form-control:focus,
.form-group .textinput:focus,
.form-group ngx-intl-tel-input .iti__flag-container .iti__selected-flag:focus,
.form-group code-input input:focus {
    border-color:#8C8C8C
}
.form-group ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CDCACA;
    font-style: italic;
    opacity: 1; /* Firefox */
}
.form-group :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CDCACA;
    font-style: italic;
}
.form-group ::-ms-input-placeholder { /* Microsoft Edge */
    color: #CDCACA;
    font-style: italic;
}
.form-group .password-style::placeholder {
    letter-spacing: 3px;
    font-style: normal;
}
.form-group .password-style {
    margin-bottom: 1rem;
}
.form-group .password-style:-ms-input-placeholder {
    letter-spacing: 3px;
    font-style: normal;
}
.form-group .password-style::-ms-input-placeholder {
    letter-spacing: 3px;
    font-style: normal;
}
.search-input ::placeholder {
    color:#CDCACA;
    font-style: italic;
    font-weight: 300;
}
.fsearch-input :-ms-input-placeholder {
    color:#CDCACA;
    font-style: italic;
    font-weight: 300;
}
.search-input ::-ms-input-placeholder {
    color:#CDCACA;
    font-style: italic;
    font-weight: 300;
}
/********************************************************************/
/**mat overridden styles******************************************************************/
.form-group .mat-form-field,
.form-group .mat-form-field-infix {
    width: 100% !important;
    padding:0 !important;
}
.form-group .mat-form-field-label {
    margin-top: 0.5em !important;
    padding-left: .75rem !important;
    line-height: 1.5rem !important;
}
.form-group .mat-form-field-type-mat-select {
    line-height: 2.8rem;
}
.form-group .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: unset;
}
.form-group .mat-form-field-wrapper {
    padding:0
}
.form-group .mat-form-field-appearance-fill .mat-form-field-flex {
    background: none;
    padding:0;
    border-radius: 0;
}
.form-group .mat-form-field-underline {
    display: none;
}
.form-group .mat-form-field-appearance-fill .mat-form-field-infix {
    padding:0
}
.form-group .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding:0
}
.form-group .mat-form-field-infix {
    padding: 0 !important;
    border: none;
}
.form-group .mat-form-field-suffix {
    position:absolute;
    right: 0;
}
.form-group .ng2-tag-input {
    border:none !important;
    padding:0 !important;
    margin-left: 1.6rem;
}
.form-group .ng2-tag-input__text-input {
    background: none !important;
}
.form-group .ng2-dropdown-menu {
    margin-top: 2.5rem;
}
.form-group .mat-form-field-type-mat-select {
    border:1px solid #DBDBDB;
    background: white;
    border-radius: 10px !important;
    height: 40px;
    padding: .375rem .75rem;
    font-size: 0.9rem;
}
.form-group .mat-select-value {
    height: 3rem;
    padding: 0 .75rem;
    line-height: 3rem;
    font-size: 0.9rem;
}
.mat-tooltip {
    background: #1C008A !important;
    position: relative;
    overflow: visible !important;
}
label .fa-exclamation-circle{
    color: #CDCACA !important;
}
.mat-tooltip:after {
    position: absolute;
    bottom: -10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #1C008A;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
}
.form-group code-input input {
    height: 3rem !important;
    width: 3rem !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 8px !important;
}
.form-group code-input span:first-child {
    padding-left: 0 !important;
}
.form-group.invalid-code code-input input {
    border-color:#E74E5A !important
}
.form-group input::-webkit-outer-spin-button,
.form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.form-group input[type=number] {
  -moz-appearance: textfield;
}
/***MAT BUTTON TOGGLE:: radio boxes*****************************************************************/
.form-group .mat-button-toggle-group {
    width: 100%;
}
.form-group .mat-button-toggle-group-appearance-standard, 
.form-group .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    border:none
}
.form-group .mat-button-toggle-button {
    margin-right: 1px;
}
.form-group .mat-button-toggle {
    border:1px solid #CDCACA;
    margin-right: 1.5rem;
    border-radius: 25px;
    flex-grow: 1;
}
.form-group .mat-group-style-1 .mat-button-toggle:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form-group .mat-group-style-1 .mat-button-toggle:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.form-group .mat-button-toggle.mat-button-toggle-checked {
    background: #FFA72E;
    color:black;
    border-color:#FFA72E
}
/*** END MAT BUTTON TOGGLE:: radio boxes*****************************************************************/
/*** DRAGDROPZONE *****************************************************************/
.wizard-dropzone .form-group .dropzone,
.import-container {
    border-radius: 8px;
    border: 2px dashed #DBDBDB;
    background:none;
    margin:0 0 1.5rem 0;
    padding:0;
    max-width: 100%;
    width: 100%;
    vertical-align: top;
    min-height: auto;
    height:12rem;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}
.wizard-dropzone .form-group .dropzone:not(.dz-started) {
    text-align: center;
}
.wizard-dropzone .form-group .dropzone.dz-started {
    border-color: #DBDBDB;
}
.wizard-dropzone .form-group .dropzone {
    font-size:0.875rem;
    color:#363636;
    opacity: 75%;
}
.wizard-dropzone .form-group .dropzone.dz-clickable {
    cursor: default;
}
.wizard-dropzone .form-group .dz-button {
    min-width: 12.5rem;
    max-width: 100%;
    height: 3.5rem;
    text-align: center;
    color: #1C008A !important;
    font-size: 0;
    font-weight: 500;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid;
    display: inline-block;
    vertical-align: top;
    line-height: 3.4rem;
    margin-top: 0.8rem;
    position: relative;
}
.wizard-dropzone .form-group .dz-button:after {
    position:absolute;
    top:0;
    height: 100%;
    left:0;
    right:0;
    margin:0 auto;
    content:"Browse";
    font-size:1rem;
}
.wizard-dropzone .form-group .dz-button:disabled {
    opacity:0.5;
    cursor: default;
    color:#fff;
}
.wizard-dropzone .form-group .dz-button:disabled:hover {
    opacity:0.5;
    color:#fff;
}
.wizard-dropzone .form-group .dz-button:hover {
    opacity:0.7;
    color:#fff;
    text-decoration: none;
}
.wizard-dropzone .form-group .dropzone .dz-image-preview {
    max-width: 90%;
    display: inline-block;
    vertical-align: top;
    min-width: 80%;
}
.wizard-dropzone .form-group .dropzone.dz-started .dropzone-text,
.wizard-dropzone .form-group .dropzone.dz-started .dz-message,
.wizard-dropzone .form-group .dropzone.dz-started .dz-error-mark,
.wizard-dropzone .form-group .dropzone.dz-started .dz-success-mark,
.wizard-dropzone .form-group .dropzone.dz-started .dz-size,
.wizard-dropzone .form-group .dropzone.dz-started .dz-image,
.wizard-dropzone .form-group .dropzone.has-files .dropzone-text,
.wizard-dropzone .form-group .dropzone.has-files .dz-message,
.wizard-dropzone .form-group .dropzone.has-files .dz-size,
.wizard-dropzone .form-group .dropzone.has-files .dz-image {
    display: none;
}
.wizard-dropzone .form-group .dropzone.dz-started .dz-image {
    width: 4rem;
    float:left;
    margin-right: 0.4rem;
}
.wizard-dropzone .form-group .dropzone.dz-started .dz-image img {
    width: 100%;
}
.wizard-dropzone .form-group .dropzone .dz-details {
    background-color: #EFEFEF;
    border-radius: 10px;
    padding: 0.4rem 2rem 0.4rem 0.5rem;
    font-size: 0.975rem;
    color: #363636;
    margin: 0.1rem 0;
}
.wizard-dropzone .form-group .dropzone.has-files .dz-filename {
    cursor: pointer !important;
    overflow: hidden;
}
.wizard-dropzone .form-group .dropzone .dz-error .dz-error-message {
    background-color: #E74E5A;
    border-radius: 10px;
    padding: 0.2rem 2rem 0.2rem 0.5rem;
    font-size: 0.875rem;
    color: #fff;
    margin: 0.1rem 0;
}
.wizard-dropzone .form-group .dropzone .dz-error .dz-details {
    display: none;
}
.wizard-dropzone .form-group .dropzone .dz-error .dz-remove {
    color:#fff
}
.wizard-dropzone .form-group .dropzone .dz-details .fa-eye {
    font-size: 1.2rem;
    float: left;
}
.wizard-dropzone .form-group .dropzone .dz-remove {
    font-weight: 500 !important;
    font-size: 1.2rem;
    float: right;
    margin-top: -2.2rem;
    margin-right: 0.5rem;
}
/*** END DRAGDROPZONE *****************************************************************/
/***alertify*****************/
.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 0;
}
.alertify-notifier .ajs-message,
.alertify-notifier {
    width: auto !important;
}
.alertify-notifier .ajs-message {
    border-radius: 15px;
}
.alertify-notifier .ajs-message {
    background-color: #0073B1 !important;
    color:#fff
}
.alertify-notifier .ajs-message.ajs-success  {
    background-color: #00B6A8 !important;
}
.alertify-notifier .ajs-message.ajs-error  {
    background-color: #E74E5A !important;
}
.alertify-notifier .ajs-message.ajs-warning  {
    background-color: #E7A54E !important;
}
.alertify-custom-msg .msg-icon {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    text-align: center;
    width: 2rem;
}
.alertify-custom-msg .msg {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-left: 1rem;
    font-size: .875rem;
}
.alertify-custom-msg .msg > b {
    width: 100%;
    display: inline-block;
}
.alertify-custom-msg .msg-close {
    text-align: center;
    float: right;
    font-size: 1.4rem;
    color: #fff;
    margin-top: 0.3rem;
}
.cdk-overlay-container .mat-tooltip {
    font-size: 0.7rem;
    max-width: 20rem;
}
.alertify .ajs-dialog {
    max-width: 345px;
    padding: 24px;
    border-radius: 20px;
    overflow: hidden;
}
.alertify .ajs-dialog .ajs-body .ajs-content {
    padding-left: 0;
    padding-right: 0;
}
.alertify .ajs-dialog .ajs-footer {
    margin-left: 0;
    margin-right: 0; 
    padding:0;
}
.alertify .ajs-dialog .ajs-button.ajs-ok,.alertify .ajs-dialog .ajs-button.ajs-cancel {
    min-width: 6.5rem;
    max-width: 100%;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    font-size:1rem;
    font-weight: 700;
    border-radius: 20px;
    cursor: pointer;
    border:none;
    text-transform: uppercase;
    float: right;
}
.alertify .ajs-dialog .ajs-button.ajs-ok {
    background-color: #00B6A8;
    color:#fff;
}
.alertify .ajs-dialog .ajs-button.ajs-cancel {
    color:#E72F3E;
    background-color:white;
    border: 1px solid;
}
.alertify .ajs-dialog .ajs-button:hover {
    opacity:0.7
}
.custom-autocomplete {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    background-color: #fff;
}
@media screen and (min-width:1025px) and (max-width:1200px) {
    html,body {
        font-size: 12px;
    }}
@media screen and (min-width:1201px) and (max-width:1400px) {
    html,body {
        font-size: 13px;
    }}
@media screen and (min-width:1401px) and (max-width:1600px) {
    html,body {
        font-size: 14px;
    }}
.trans,
.agreement-info-step,
.dashboard-middle,
.dashboard-left,
.wizard-stepper-block,
.doc-block:hover .Same-Doc {
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.4s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.4s;
    -moz-transition-timing-function: linear;
    -moz-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.4s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.btn-style-1,
.btn-style-2,
.btn-style-3,
.btn-style-4,
.btn-style-5,
.mat-button-toggle,
.navbar-Dropdown,
ul.navbar-iconslist li,
ul.navbar-iconslist li a,
ul.navbar-primary-list li a,
ul.menu-style-1 li a,
ul.tabs-style-2 li a,
.sorting-dropdown .Sort-agreements {
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -moz-transition-property: all;
    -moz-transition-duration: 0.3s;
    -moz-transition-timing-function: linear;
    -moz-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.3s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.dashboard-middle,
.dashboard-left {
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -ms-transition-delay: 0.4s;
    transition-delay: 0.4s;
}
.flip-card {
    width: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.flip-card-front, .flip-card-back {
    width: 100%;
    height: max-content;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.flip-card-back {
    transform: rotateY(180deg);
}
.flip-card.with-hover:hover,
.flip-card.show,
.flip-card.active,
.flip-card.visible {
    transform: rotateY(180deg);
}
